.my-node-enter {
  opacity: 0;
  transform: translateY(50px);
}
.my-node-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition: all 500ms ease-in;
  transition-delay: 100ms;
}
.my-node-exit {
  opacity: 1;
}
.my-node-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}


.alert-enter {
  opacity: 0;
  transform: translateX(-90px);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 500ms, transform 500ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 500ms, transform 500ms;
}